import React from 'react';
import './VideoGrid.scss';

const videoUrls = [
    'https://sowerinteractive.com/VID01.mp4',
    'https://sowerinteractive.com/VID02.mp4',
    'https://sowerinteractive.com/VID03.mp4',
    'https://sowerinteractive.com/VID04.mp4'
];

const VideoGrid = () => {
    return (
        <div className="video-grid">
            {videoUrls.map((url, index) => (
                <video
                    key={index}
                    src={url}
                    autoPlay
                    loop
                    muted
                    playsInline
                    className="video-item"
                />
            ))}
        </div>
    );
};

export default VideoGrid;