import React from 'react';
import './Footer.scss'

const Footer = () => {
    return (
        <div className="footer">
            Copyright © 2025 Sower Interactive LLC. All rights reserved.
        </div>
    );
};


export default Footer;
